import { TextField, Typography } from '@mui/material';
import React, {  useState } from 'react';
import { LoadingButton } from '@mui/lab';
import './ListModels.scss';

const AddModal = () => {
  const [name, setName] = useState('');
  const [type, setType] = useState('chat');
  const [nameError, setNameError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (propName, value) => {
    switch (propName) {
      case 'name':
        setName(value || '');
        break;
      default:
        break;
    }
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let valid = true;
    if (!name) {
      valid = false;
      setNameError(true);
    } else {
      setNameError(false);
    }

   
    if (valid) {
      const sendingData = {
        name: name,
        type: type
      };

        await fetch(process.env.REACT_APP_API_URL + '/model', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(sendingData)
        }).then((response) => {
          if (response.status === 201) {
            return response.json();
          } else if (response.status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/#/bejelentkezes';
            window.location.reload(false);
          }
        }).then((sendingData) => {
          setLoading(false);
          console.log(sendingData);
          window.location.reload();
        });
        
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
        <Typography
          textAlign={'center'}
          fontWeight={'bold'}
          marginBottom={'2rem'}
        >
        Új modell hozzáadása
        </Typography>
        <TextField
          multiline={true}
          helperText={nameError ? 'Név megadása kötelező' : ''}
          label={'Modell neve'}
          fullWidth={true}
          type='text'
          id='name'
          onChange={ (event) => {handleChange(`name`, event.target.value); setNameError(false)}}
          value={name}
          style={{marginBottom: '1rem'}}
          error={nameError}
        />
        <a href='https://platform.openai.com/docs/models' target='_blank' rel='noreferrer'>Link a modellek neveihez - OpenAI API dokumentáció</a>
        <div style={{marginBottom: '20px' }}>A táblázat 'Model' oszlopában szereplő azonosítót írd be ide, pl.: gpt-4-1106-preview</div>

        <Typography>
          Típus
        </Typography>
        <div className='type-switch'>
          <div>
            <input
              type="radio"
              value="chat"
              checked={type === 'chat'}
              onChange={handleTypeChange}
            />
            <label htmlFor="chat">Chat</label>
          </div>
          <div>
            <input
              type="radio"
              value="completion"
              checked={type === 'completion'}
              onChange={handleTypeChange}
            />
            <label htmlFor="completion">Completion (Legacy)</label>
          </div>
        </div>
        
    <LoadingButton
      variant='contained'
      size='large'
      type='submit'
      loading={loading}
      onClick={handleSubmit}
      style={{marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto', display: 'block'}}
    >
      {
        loading ? 'Feldolgozás...' :  'Hozzáadás'
      }
    </LoadingButton>
    {
      nameError && (
        <Typography style={{color: 'red'}}>
          A név megadása kötelező!
        </Typography>
      )
    }
    </div>
  );
};

export default AddModal;
