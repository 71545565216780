import React, { useState } from 'react';
import axios from 'axios';
import { Alert, Button, Grid, Snackbar, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const Convert = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    // Create a new FormData object to upload file
    const formData = new FormData();
    formData.append('file', file);

    localStorage.setItem('convert-uploaded', file.name.substr(0, file.name.lastIndexOf('.')));

    axios
      .post(process.env.REACT_APP_API_URL + '/book/convert', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.status === 201) {
          //Download response file
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const fileName = localStorage.getItem('convert-uploaded') || 'file';
          link.href = url;
          link.setAttribute('download', `${fileName}.txt`);
          document.body.appendChild(link);
          document.getElementById('convertUpload').value = '';
          link.click();
          setOpen(true);
          setFile(null);
          setFileName(null);
          setLoading(false);
          localStorage.removeItem('convert-uploaded');
        }
      })
      .catch((error) => {
        console.log(error);

        setError(true);
        setLoading(false);
        setFile(null);
        setFileName(null);
        setErrorOpen(true);

        if (error.response.status === 403 || error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href = '/#/bejelentkezes';
          window.location.reload(false);
        }
      });
  }

  // This function handles changes to the file input.
  // The event parameter is the event that triggered the change.
  // The event.target.files list contains the files that were selected.
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      setFileName(event.target.files[0].name || '');
    } else {
      setFileName('');
    }
    setError(false);
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography
          textAlign={'left'}
          minHeight={'4rem'}
          display={'flex'}
          alignItems={'center'}
          fontWeight={'bold'}
        >
          1. Ekönyv átalakítsa szöveggé{' '}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form autoComplete='off' onSubmit={handleSubmit}>
          <div className='addfile'>
            <Typography>
              Könyv hozzáadása (kizárólag .epub, .mobi, .pdf fájl!)
            </Typography>
            {fileName && (
              <Typography>Jelenleg kiválasztott: {fileName}</Typography>
            )}
            <Button
              variant='outlined'
              color='secondary'
              component='label'
              sx={{ mb: 2 }}
            >
              Könyv feltöltése
              <input
                hidden
                type='file'
                onChange={handleFileChange}
                id='convertUpload'
                accept='.pdf, .epub, .mobi'
              ></input>
            </Button>
          </div>
          <LoadingButton
            variant='contained'
            size='large'
            type='submit'
            disabled={!file}
            loading={loading}
          >
            Átalakítás
          </LoadingButton>
        </form>
        {error && (
          <Alert severity='error' sx={{ marginTop: 4 }}>
            Hiba történt az átalakítás során!
          </Alert>
        )}
        <Snackbar open={open} autoHideDuration={5000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
            Sikeres átalakítás!
          </Alert>
        </Snackbar>

        <Snackbar open={errorOpen} autoHideDuration={5000} onClose={() => setErrorOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
            Hiba történt az átalakítás során!
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};

export default Convert;
