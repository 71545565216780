import React from 'react';
import { LoadingButton } from '@mui/lab';

const AbortProcessing = ({ loading }) => {
  const [abortLoading, setAbortLoading] = React.useState(false);

  const handleList = async (id) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + '/book/abort',
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          return response.json();
        }
      })
      .then((data) => {
        setAbortLoading(true);
      });
  };

  if (loading) {
    return (
      <>
        <LoadingButton
          onClick={handleList}
          variant='contained'
          color='error'
          loading={abortLoading}
          loadingIndicator='Megszakítás...'
        >
          Megszakítás
        </LoadingButton>
        {
          abortLoading && (
            <div>
              Megszakítás folyamatban...<br />A folyamat befejezése előtt <span style={{color: 'red', fontWeight: '700'}}>NE</span> zárd be a böngésző ablakot!
            </div>
          )
        }
      </>
    );
  }
  return null;
};

export default AbortProcessing;
