import { Accordion, AccordionDetails, AccordionSummary, Slider, TextField, Typography } from '@mui/material';
import React, {  useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import './ListSchemas.scss';

const EditSchema = ({ data }) => {
  const [name, setName] = useState( data ? data.name : '');
  const [prompt0, setPrompt0] = useState(data ? data.prompt0.prompt : '');
  const [prompt1200, setPrompt1200] = useState(data ? data.prompt1200.prompt : '');
  const [prompt2000, setPrompt2000] = useState(data ? data.prompt2000.prompt : '');
  const [prompt2800, setPrompt2800] = useState(data ? data.prompt2800.prompt : '');

  const [tokenValue0, setTokenValue0] = useState(data ? data.prompt0.maxtoken : 0);
  const [tokenValue1200, setTokenValue1200] = useState(data ? data.prompt1200.maxtoken : 0);
  const [tokenValue2000, setTokenValue2000] = useState(data ? data.prompt2000.maxtoken : 0);
  const [tokenValue2800, setTokenValue2800] = useState(data ? data.prompt2800.maxtoken : 0);

  const [temperature0, setTemperature0] = useState(data ? data.prompt0.temperature : 0.7);
  const [temperature1200, setTemperature1200] = useState(data ? data.prompt1200.temperature : 0.7);
  const [temperature2000, setTemperature2000] = useState(data ? data.prompt2000.temperature : 0.7);
  const [temperature2800, setTemperature2800] = useState(data ? data.prompt2800.temperature : 0.7);

  const [validRatio, setValidRatio] = useState(data ? data.validRatio : 0);

  const [nameError, setNameError] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const [promptError, setPromptError] = useState(false);
  const [loading, setLoading] = useState(false);

  const variants = [
    {value: '0',    text: '0-1200 közötti'},
    {value: '1200', text: '1200-2000 közötti'},
    {value: '2000', text: '2000-2800 közötti'},
    {value: '2800', text: '2800 feletti'}
  ];

  const handleChange = (propName, value) => {
    switch (propName) {
      case 'name':
        setName(value || '');
        break;
      case 'validRatio':
        setValidRatio(value || 0);
        break;
      case 'prompt0':
        setPrompt0(value || '');
        break;
      case 'prompt1200':
        setPrompt1200(value || '');
        break;
      case 'prompt2000':
        setPrompt2000(value || '');
        break;
      case 'prompt2800':
        setPrompt2800(value || '');
        break;
      case 'tokenValue0':
        setTokenValue0(value || 0);
        break;
      case 'tokenValue1200':
        setTokenValue1200(value || 0);
        break;
      case 'tokenValue2000':
        setTokenValue2000(value || 0);
        break;
      case 'tokenValue2800':
        setTokenValue2800(value || 0);
        break;
      case 'temperature0':
        setTemperature0(value || 0);
        break;
      case 'temperature1200':
        setTemperature1200(value || 0);
        break;
      case 'temperature2000':
        setTemperature2000(value || 0);
        break;
      case 'temperature2800':
        setTemperature2800(value || 0);
        break;
      default:
        break;
    }
  };

  const getValues = (propName) => {
    switch (propName) {
      case 'name':
        return name;
      case 'validRatio':
        return validRatio;
      case 'prompt0':
        return prompt0;
      case 'prompt1200':
        return prompt1200;
      case 'prompt2000':
        return prompt2000;
      case 'prompt2800':
        return prompt2800;
      case 'tokenValue0':
        return tokenValue0;
      case 'tokenValue1200':
        return tokenValue1200;
      case 'tokenValue2000':
        return tokenValue2000;
      case 'tokenValue2800':
        return tokenValue2800;
      case 'temperature0':
        return temperature0;
      case 'temperature1200':
        return temperature1200;
      case 'temperature2000':
        return temperature2000;
      case 'temperature2800':
        return temperature2800;
      default:
        return '';
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let valid = true;
    if (!name) {
      valid = false;
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (!prompt0 || !prompt1200 || !prompt2000 || !prompt2800) {
      valid = false;
      setPromptError(true);
    } else {
      setPromptError(false);
    }
    if (!tokenValue0 || !tokenValue1200 || !tokenValue2000 || !tokenValue2800) {
      valid = false;
      setTokenError(true);
    } else {
      setTokenError(false);
    }
    if (valid) {
      const sendingData = {
        name: name,
        validRatio: validRatio,
        prompt0: {
          prompt: prompt0 + ' ',
          maxtoken: tokenValue0,
          temperature: temperature0
        },
        prompt1200: {
          prompt: prompt1200 + ' ',
          maxtoken: tokenValue1200,
          temperature: temperature1200
        },
        prompt2000: {
          prompt: prompt2000 + ' ',
          maxtoken: tokenValue2000,
          temperature: temperature2000
        },
        prompt2800: {
          prompt: prompt2800 + ' ',
          maxtoken: tokenValue2800,
          temperature: temperature2800
        }

      };

      if ( data ) {
        await fetch(process.env.REACT_APP_API_URL + '/schema/' + data._id, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(sendingData)
        }).then((response) => {
          if (response.status === 201) {
            return response.json();
          } else if (response.status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/#/bejelentkezes';
            window.location.reload(false);
          }
        }).then((sendingData) => {
          setLoading(false);
          console.log(sendingData);
          window.location.reload();

        });
      } else {
        await fetch(process.env.REACT_APP_API_URL + '/schema', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(sendingData)
        }).then((response) => {
          if (response.status === 201) {
            return response.json();
          } else if (response.status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/#/bejelentkezes';
            window.location.reload(false);
          }
        }).then((sendingData) => {
          setLoading(false);
          console.log(sendingData);
          window.location.reload();
        });
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
        <Typography
          textAlign={'center'}
          fontWeight={'bold'}
          marginBottom={'2rem'}
        >
        {
          data ? `${data.name} sablon szerkesztése` : 'Új sablon hozzáadása'
        }
        </Typography>
        <TextField
          multiline={true}
          helperText={nameError ? 'Név megadása kötelező' : ''}
          label={'Sablon neve'}
          fullWidth={true}
          type='text'
          id='name'
          onChange={ (event) => {handleChange(`name`, event.target.value); setNameError(false)}}
          value={getValues(`name`)}
          style={{marginBottom: '1rem'}}
          error={nameError}
        />

      {
        variants.map((variant, index) => {
          return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{variant.text} szószám beállításai:</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <TextField
              multiline={true}
              helperText={promptError ? 'Prompt megadása kötelező' : ''}
              label={'Prompt'}
              fullWidth={true}
              type='text'
              id='prompt'
              onChange={ (event) => {handleChange(`prompt${variant.value}`, event.target.value); setPromptError(false)}}
              value={getValues(`prompt${variant.value}`)}
              style={{marginBottom: '1rem'}}
              error={promptError}
            />
            <TextField
              multiline={true}
              helperText={tokenError ? 'Maxtoken megadása kötelező' : ''}
              label={'Maxtoken'}
              fullWidth={true}
              type='number'
              id='maxtoken0'
              onChange={ (event) => {handleChange(`tokenValue${variant.value}`, parseInt(event.target.value)); setTokenError(false)}}
              value={getValues(`tokenValue${variant.value}`)}
              error={tokenError}
              inputProps={{ min: 0, max: 8192 }}
              style={{marginBottom: '1rem'}}
            />
            <Typography>
              Temperature: {getValues(`temperature${variant.value}`)}
            </Typography>
            <Slider
              aria-label='Temperature'
              valueLabelDisplay='auto'
              step={0.1}
              marks
              min={0}
              max={2}
              label={'Temperature'}
              value={getValues(`temperature${variant.value}`)}
              onChange={ (event) => {handleChange(`temperature${variant.value}`, event.target.value)}}
            />

            </AccordionDetails>
          </Accordion>
        )})
      }
        <TextField
          multiline={true}
          label={'Összehasonlítás arány'}
          fullWidth={true}
          type='text'
          id='validRatio'
          onChange={ (event) => {handleChange(`validRatio`, event.target.value)}}
          value={getValues(`validRatio`)}
          style={{marginBottom: '1rem', marginTop: '1rem'}}
        />
    <LoadingButton
      variant='contained'
      size='large'
      type='submit'
      loading={loading}
      onClick={handleSubmit}
      style={{marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto', display: 'block'}}
    >
      {
        loading ? 'Feldolgozás...' : data ? 'Módosítás' : 'Hozzáadás'
      }
    </LoadingButton>
    {
      nameError && (
        <Typography style={{color: 'red'}}>
          A név megadása kötelező!
        </Typography>
      )
    }
    {
      promptError && (
        <Typography style={{color: 'red'}}>
          A prompt megadása kötelező!
        </Typography>
      )
    }
    {
      tokenError && (
        <Typography style={{color: 'red'}}>
          A maxtoken megadása kötelező!
        </Typography>
      )
    }
    </div>
  );
};

export default EditSchema;
