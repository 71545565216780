import React, { useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { Container } from '@mui/system';

function RegisterPage() {
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [registerError, setRegisterError] = useState(false);
  let [success, setSuccess] = useState();

  function registerUser(event) {
    event.preventDefault();
    axios
      .post(
        process.env.REACT_APP_API_URL + '/users/',
        {
          email,
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((result) => {
        if (result.status === 201) {
          setSuccess(true);
          setTimeout(function () {
            window.location.href = '/#/';
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        setRegisterError(true);
      });
  }
  if (localStorage.getItem('token') && localStorage.getItem('user_id')) {
    return (
      <Container sx={{ display: 'inline-grid' }}>
        <h1>Regisztráció</h1>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              type='email'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setRegisterError(false);
              }}
              className='form-control'
              id='email'
              aria-describedby='Email cím'
              placeholder='E-mail cím'
              variant='outlined'
              error={registerError}
              label='E-mail cím'
              required
              sx={{ mb: 2 }}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant='outlined'
              required
              error={registerError}
              className='form-control'
              sx={{ mb: 4 }}
            >
              <InputLabel htmlFor='outlined-adornment-password'>
                Jelszó
              </InputLabel>
              <OutlinedInput
                id='outlined-adornment-password'
                value={password}
                type='password'
                inputProps={{ minLength: 6, maxLength: 32 }}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setRegisterError(false);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='contained'
              size='large'
              type='submit'
              aria-label='Regisztráció'
              onClick={registerUser}
            >
              Regisztráció
            </Button>
          </Grid>
        </Grid>

        {success && (
          <Grid item xs={12}>
            <Alert severity='success' sx={{ marginTop: 4 }}>
              Sikeres regisztráció
            </Alert>
          </Grid>
        )}
      </Container>
    );
  } else {
    window.location.href = '/#/';
    window.location.reload(false);
  }
}

export default RegisterPage;
