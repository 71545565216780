import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Alert, Button, Snackbar, Tab, TextField } from '@mui/material';
import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { useEffect } from 'react';
import ObjectToTable from './ObjectToTable';

const AnswerPanels = ({ answer, savePrompt }) => {
  const [value, setValue] = useState('0');
  const [textValue, setTextValue] = useState('');
  const [compareValue, setCompareValue] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDownload = (file, downloadFileName) => {
    const blob = new Blob([file], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, `${downloadFileName}.txt`);
  };

  const handleDownloadCSV = (data) => {
    const csvContent = "data:text/csv;charset=utf-8," +
    'Eredeti szöveg\tChatGPT válasz\tEredeti szószám\tVálasz szószám\tVálasz/Eredeti arány\n' +
      data.map(row => 
        row.original.replaceAll('\n', '') + '\t' +
        row.message.replaceAll('\n', '') + '\t' +
        row.originalWordCount + '\t' +
        row.generatedWordCount + '\t' +
        Math.round(row.generatedWordCount / row.originalWordCount * 100) / 100
      ).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", localStorage.getItem('fragment-uploaded') + '-tablazatos-osszehasonlitas.csv' || 'feldolgozott-konyv-tablazatos-osszehasonlitas.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fillAnswers = (answer) => {
    if (!answer) return '';
    let textValue = '';
    answer.forEach(element => {
      element.original = element.original.replaceAll('\n', '');
      element.message = element.message.replaceAll('\n', '');
      textValue += element.message + '\n\n';
    });
    return textValue;
  }

  const fillCompare = (answer) => {
    if (!answer) return '';
    let textValue = '';
    answer.forEach(element => {
      element.original = element.original.replaceAll('\n', '');
      element.message = element.message.replaceAll('\n', '');

      if (element.original !== '') {
        textValue += element.original + '\n\n';
      }
      textValue += element.message + '\n\n';
      textValue += '-'.repeat(50) + '\n\n';
    });
    return textValue;
  }
  
  useEffect(() => {
    if (answer.length > 0) {
      setTextValue(fillAnswers(answer));
      setCompareValue(fillCompare(answer));
      localStorage.setItem('answers', textValue);
      localStorage.setItem('compare', compareValue);
    }
  }, [answer, compareValue, textValue]);

  return (
    <div>
      <TabContext value={value}>
        <TabList onChange={handleChange} variant='scrollable'  >
          <Tab label='ChatGPT válasz' value='0' />
          {savePrompt && <Tab label='Összehasonlítás' value='1' />}
          {savePrompt && <Tab label='Táblázat nézet' value='2' />}
        </TabList>
        <TabPanel value='0'>
          <Button
            variant='contained'
            size='large'
            onClick={() => handleDownload(textValue, localStorage.getItem('fragment-uploaded') + '-chatgpt' || 'feldolgozott-konyv')}
            sx={{ mb: 2, mt: 2 }}
          >
            Letöltés (.txt)
          </Button>
          <TextField
            multiline={true}
            fullWidth={true}
            value={textValue}
            maxRows={30}>
          </TextField>
        </TabPanel>
        {
          savePrompt &&
          <TabPanel value='1'>
            <Button
              variant='contained'
              size='large'
              onClick={() =>
                handleDownload(compareValue, localStorage.getItem('fragment-uploaded') + '-osszehasonlitas' || 'feldolgozott-konyv-osszehasonlitas')}
              sx={{ mb: 2, mt: 2 }}
            >
              Letöltés (.txt)
            </Button>
            <TextField
              multiline={true}
              fullWidth={true}
              value={compareValue}
              maxRows={30}>
            </TextField>
          </TabPanel>
        }
        {
          savePrompt &&
          <TabPanel value='2'>
            <Button
              variant='contained'
              size='large'
              onClick={() => handleDownloadCSV(answer)}
              sx={{ mb: 2, mt: 2 }}
            >
              Letöltés (.csv)
            </Button>
            <ObjectToTable data={answer} />
          </TabPanel>
        }
      </TabContext>
    </div>
  );
}

export default AnswerPanels;
