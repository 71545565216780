// Function to request a wake lock
export const requestWakeLock = async () => {
  let wakeLock = null;

  try {
    wakeLock = await navigator.wakeLock.request('screen');
    console.log('Wake lock is active!');
  } catch (err) {
    console.error(`${err.name}, ${err.message}`);
  }

  return wakeLock;
};

// Function to release a wake lock
export const releaseWakeLock = async (wakeLock) => {
  if (wakeLock !== null) {
    try {
      await wakeLock.release();
      console.log('Wake lock released!');
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  }
};
