import React from 'react';
import { Container, Grid } from '@mui/material';
import Convert from '../Components/Books/Convert';
import Fragment from '../Components/Books/Fragment.v10';
import FAQ from '../Components/FAQ/FAQ';
import LoadPrevious from '../Components/Books/LoadPrevious';

const MainPageGPT4 = () => {
  if (localStorage.getItem('user_id') && localStorage.getItem('token')) {
    return (
      <>
        <Container sx={{ marginBottom: 4 }}>
          <Grid container spacing={2}>
            <Convert />
            <Fragment version='4' />
            <FAQ version='4' />
            {
              localStorage.getItem('answers') && localStorage.getItem('compare') && (
            <LoadPrevious />
              )
            }
          </Grid>
        </Container>
      </>
    );
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href = '/#/bejelentkezes';
    window.location.reload(false);
  }
};

export default MainPageGPT4;
