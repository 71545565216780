import React, { useState, useEffect } from 'react';
import { Alert, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, MenuItem, Modal, Select, Slider, Switch, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AnswerPanels from './AnswerPanels';
import { Box } from '@mui/system';
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import SchemaDetails from '../RequestSchemas/SchemaDetails';
import AbortProcessing from './AbortProcessing';
import { releaseWakeLock, requestWakeLock } from './WakeLock';

const Fragment = ({ version = '3.5' }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [error, setError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const [answer, setAnswer] = useState([]);
  const [tokenValue, setTokenValue] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [isTokenChecked, setIsTokenChecked] = useState(true);
  const [savePrompt, setSavePrompt] = useState(true);
  const [temperature, setTemperature] = useState(0.7);
  const [model, setModel] = useState();
  const [models, setModels] = useState();
  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => setOpenModal(false);

  const [requestSchema, setRequestSchema] = useState({});
  const [selectedSchema, setSelectedSchema] = useState({});
  const [validRatio, setValidRatio] = useState(localStorage.getItem('wordRatio') || 0.75 );

  const [prompt0Value, setPrompt0Value] = useState('');
  const [prompt1200Value, SetPrompt1200Value] = useState('');
  const [prompt2000Value, SetPrompt2000Value] = useState('');
  const [prompt2800Value, SetPrompt2800Value] = useState('');


  const defaultTextValue = process.env.REACT_APP_DEFAULT_PROMPT;
  const defaultTokenValue = process.env.REACT_APP_DEFAULT_MAXTOKEN;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };



  const handleBeforeUnload = (e) => {
    e.returnValue = 'Data may not be saved';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    

    window.addEventListener('beforeunload', handleBeforeUnload, true);
    const wakeLock = await requestWakeLock();

    setLoading(true);
    setAnswer([]);

    if (!prompt0Value) {
      setPrompt0Value(defaultTextValue);
    }
    if (!prompt1200Value) {
      SetPrompt1200Value(defaultTextValue);
    }
    if (!prompt2000Value) {
      SetPrompt2000Value(defaultTextValue);
    }
    if (!prompt2800Value) {
      SetPrompt2800Value(defaultTextValue);
    }

    if (!tokenValue) {
      setTokenValue(defaultTokenValue);
    }

    // Create a new FormData object to upload file
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user', localStorage.getItem('user_id'));
    formData.append('model', model.name || 'gpt-4');
    formData.append('requestType', model.type || 'chat');

    formData.append('prompt0',          ( selectedSchema._id !== '0' ? selectedSchema.prompt0.prompt : prompt0Value )        || defaultTextValue);
    formData.append('prompt1200',       ( selectedSchema._id !== '0' ? selectedSchema.prompt1200.prompt : prompt1200Value )  || defaultTextValue);
    formData.append('prompt2000',       ( selectedSchema._id !== '0' ? selectedSchema.prompt2000.prompt : prompt2000Value )  || defaultTextValue);
    formData.append('prompt2800',       ( selectedSchema._id !== '0' ? selectedSchema.prompt2800.prompt : prompt2800Value )  || defaultTextValue);
    formData.append('tokenValue0',      ( selectedSchema._id !== '0' ? selectedSchema.prompt0.maxtoken : tokenValue )        || defaultTokenValue);
    formData.append('tokenValue1200',   ( selectedSchema._id !== '0' ? selectedSchema.prompt1200.maxtoken : tokenValue )     || defaultTokenValue);
    formData.append('tokenValue2000',   ( selectedSchema._id !== '0' ? selectedSchema.prompt2000.maxtoken : tokenValue )     || defaultTokenValue);
    formData.append('tokenValue2800',   ( selectedSchema._id !== '0' ? selectedSchema.prompt2800.maxtoken : tokenValue )     || defaultTokenValue);
    formData.append('temperature0',     ( selectedSchema._id !== '0' ? selectedSchema.prompt0.temperature : temperature )    || 0.7);
    formData.append('temperature1200',  ( selectedSchema._id !== '0' ? selectedSchema.prompt1200.temperature : temperature ) || 0.7);
    formData.append('temperature2000',  ( selectedSchema._id !== '0' ? selectedSchema.prompt2000.temperature : temperature ) || 0.7);
    formData.append('temperature2800',  ( selectedSchema._id !== '0' ? selectedSchema.prompt2800.temperature : temperature ) || 0.7);
    formData.append('validRatio',       ( selectedSchema._id !== '0' ? selectedSchema.validRatio : validRatio )              || 0);

    localStorage.setItem('fragment-uploaded', file.name.substr(0, file.name.lastIndexOf('.')));
    localStorage.setItem('wordRatio', selectedSchema.validRatio || validRatio);

    await fetch(process.env.REACT_APP_API_URL + '/book/fragment', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(response => {
      const reader = response.body.getReader();
      let chunks = [];
      return reader.read().then(function processResult(result) {
        if (result.done) {
          return chunks.join('');
        }
        try {
          chunks.push(result.value);
          const responseText = new TextDecoder().decode(result.value);
          const responseObject = JSON.parse(responseText);
          setAnswer(prevAnswer => [...prevAnswer, responseObject]);

          let validRatioNumber = localStorage.getItem('wordRatio') ? Number(localStorage.getItem('wordRatio')) : 0;
          if ((responseObject.generatedWordCount / responseObject.originalWordCount >= validRatioNumber) && validRatioNumber !== 0) {
            handleEnqueSnackbar('warning');
          }

          if (responseObject.status === 'error') {
            setErrorOpen(true);
          } else {
            localStorage.setItem('allChunks', responseObject.countOfFiles);
            localStorage.setItem('currentProcess', responseObject.process);
            setErrorOpen(false);
          }

          return reader.read().then(processResult);
        }
        catch (error) {
          try {
            const responseText = new TextDecoder().decode(result.value);
            const firstClosingBraceIndex = responseText.indexOf('}');
            const firstJSONObjectString = responseText.slice(0, firstClosingBraceIndex + 1);

            // Extract the remaining text after the first JSON object
            const remainingText = responseText.slice(firstClosingBraceIndex + 1);

            // Find the index of the next closing curly brace in the remaining text
            const secondClosingBraceIndex = remainingText.indexOf('}');

            // Extract the second JSON object string
            const secondJSONObjectString = remainingText.slice(0, secondClosingBraceIndex + 1);

            // Parse the JSON objects
            const firstJSONObject = JSON.parse(firstJSONObjectString);
            const secondJSONObject = JSON.parse(secondJSONObjectString);

            setAnswer(prevAnswer => [...prevAnswer, firstJSONObject]);
            setAnswer(prevAnswer => [...prevAnswer, secondJSONObject]);

            let validRatioNumber = localStorage.getItem('wordRatio') ? Number(localStorage.getItem('wordRatio')) : 0;
            if ((firstJSONObject.generatedWordCount / firstJSONObject.originalWordCount >= validRatioNumber) && validRatioNumber !== 0) {
              handleEnqueSnackbar('warning');
            }
            if ((secondJSONObject.generatedWordCount / secondJSONObject.originalWordCount >= validRatioNumber) && validRatioNumber !== 0) {
              handleEnqueSnackbar('warning');
            }

            if (firstJSONObject.status === 'error') {
              setErrorOpen(true);
            } else {
              setErrorOpen(false);
              localStorage.setItem('currentProcess', firstJSONObject.process);
            }

            if (firstJSONObject.status === 'error') {
              setErrorOpen(true);
            } else {
              setErrorOpen(false);
              localStorage.setItem('currentProcess', secondJSONObject.process);
            }

            setErrorOpen(false);
            return reader.read().then(processResult);

          } catch (error) {
            console.log(error);
            setErrorOpen(true);

          }
          console.log(error);
          return reader.read().then(processResult);
        }
      });
    }).then(response => {
      setLoading(false);
      window.removeEventListener('beforeunload', handleBeforeUnload, true);
      setFile(null);
      setFileName('');
      setFileError(false);
      setProcessed(true);
      document.getElementById('fragmentUpload').value = '';
      releaseWakeLock(wakeLock);

    })
    .catch(error => {
      console.log(error);
      setErrorOpen(true);
      setFileError(true);
      setLoading(false);

      window.removeEventListener('beforeunload', handleBeforeUnload, true);
      setFile(null);
      setFileName(null);
      setProcessed(true);
      handleEnqueSnackbar('error');
      releaseWakeLock(wakeLock);
    });

  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setFileError(false);
    setErrorOpen(false);
    setProcessed(false);
    if (event.target.files[0]) {
      setFileName(event.target.files[0].name || '');
    } else {
      setFileName('');
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setError(false);
    if (event.target.checked) {
      setPrompt0Value(defaultTextValue);
      SetPrompt1200Value(defaultTextValue);
      SetPrompt2000Value(defaultTextValue);
      SetPrompt2800Value(defaultTextValue);

    } else {
      setPrompt0Value('');
      SetPrompt1200Value('');
      SetPrompt2000Value('');
      SetPrompt2800Value('');
    }
  };

  const handleTextChange = (event, index) => {
    switch (index) {
      case 0:
        setPrompt0Value(event.target.value);
        break;
      case 1:
        SetPrompt1200Value(event.target.value);
        break;
      case 2:
        SetPrompt2000Value(event.target.value);
        break;
      case 3:
        SetPrompt2800Value(event.target.value);
        break;
      default:
        break;
    }
    setError(false);
  };

  const handleTokenChange = (event) => {
    let newValue = event.target.value;
    const maxValue = model === 'gpt-4' ? 8192 : 4096;
    if (newValue === '' || /^\d+$/.test(newValue)) {
      if (newValue >= maxValue) {
        newValue = maxValue;
      }
      setTokenValue(newValue);
      setTokenError(false);
    }
  };

  const handleTemperatureChange = (event) => {
    let newValue = event.target.value;
    setTemperature(newValue);
  };

  const handleTokenCheckboxChange = (event) => {
    setIsTokenChecked(event.target.checked);
    setTokenError(false);
    if (event.target.checked) {
      setTokenValue('1500');
    } else {
      setTokenValue('');
    }
  };

  const handleSavePromptChange = (event) => {
    setSavePrompt(event.target.checked);
  };

  const handleRequestSchemaChange = (event) => {
    if (event.target.value !== '0') {
      setSelectedSchema(requestSchema.find(schema => schema._id === event.target.value));
    }
    else {
      setSelectedSchema({
        _id: '0',
        name: 'Teszt',
        prompt0: '',
        prompt1200: '',
        prompt2000: '',
        prompt2800: '',
        tokenValue0: 0,
        tokenValue1200: 0,
        tokenValue2000: 0,
        tokenValue2800: 0,
        temperature0: 0.7,
        temperature1200: 0.7,
        temperature2000: 0.7,
        temperature2800: 0.7,
        validRatio: 0
      });
    }

    setProcessed(false);
  };

  const handleModelChange = (event) => {
    setModel(models.find(model => model._id === event.target.value));
  };

  const handleEnqueSnackbar = (variant) => {
    switch (variant) {
      case 'success':
        enqueueSnackbar('A válasz megérkezett a ChatGPT-től!', {variant : 'success' , anchorOrigin: { vertical: 'top', horizontal: 'right' }, preventDuplicate: true});
        break;
      case 'error':
        enqueueSnackbar('Hiba történt a ChatGPT-vel való kommunikáció során!', {variant : 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, preventDuplicate: true});
        break;
      case 'warning':
        enqueueSnackbar('A generált szöveg hossza nem éri el a szükséges rövidítési arányt!', {variant : 'warning' , anchorOrigin: { vertical: 'top', horizontal: 'right' }});
        break;
      default:
        enqueueSnackbar('A válasz megérkezett a ChatGPT-től!', {variant : 'success' , anchorOrigin: { vertical: 'top', horizontal: 'right' }, preventDuplicate: true});
        break;
    }
  }

  const fetchModels = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL + '/model', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }).then((response) => {
        if (response.status === 201) {
          return response.json();
        } else if (response.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href = '/#/bejelentkezes';
          window.location.reload(false);
        }
      }).then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          const reorderedData = [...data];
          const defaultModelIndex = data.findIndex((model) => model.default === true);
          if (defaultModelIndex !== -1) {
            const defaultModel = reorderedData.splice(defaultModelIndex, 1)[0];
            reorderedData.unshift(defaultModel);
          }
          setModels(reorderedData);

          if (models && models.length > 0) {
            if (models.length === 1) {
              setModel(models[0]);
            } else {
              const defaultModel = models.find((model) => model.default === true);
              if (defaultModel) {
                setModel(defaultModel);
              } else {
                setModel(models[0]);
              }
            }
          }
        }
      });
    }

    if (!models || models === undefined || !Array.isArray(models) || !models.length || models.length === 0 || !model || !model._id || !models.find((model) => model._id === model._id)) {
      fetchModels();
    }

  useEffect(() => {

    if (isChecked) {
      setPrompt0Value(defaultTextValue);
      SetPrompt1200Value(defaultTextValue);
      SetPrompt2000Value(defaultTextValue);
      SetPrompt2800Value(defaultTextValue);
    }
    if (isTokenChecked) {
      setTokenValue(defaultTokenValue);
    }
    if (errorOpen) {
      handleEnqueSnackbar('error');
    } else if (!loading && !errorOpen && answer.length > 0 && processed) {
      handleEnqueSnackbar('success');
    }

    async function fetchData() {
      // You can await here
      const response = await fetch(process.env.REACT_APP_API_URL + '/schema', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }).then((response) => {
        if (response.status === 201) {
          return response.json();
        } else if (response.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href = '/#/bejelentkezes';
          window.location.reload(false);
        }
      }).then((data) => {
        setRequestSchema(data);

        if ( !selectedSchema._id ) {
          setSelectedSchema(data[0]);
        }
      }
      );
    }
    fetchData();

    let wakeLock = null;
    return () => {
      releaseWakeLock(wakeLock);
    };

  }, [isChecked, isTokenChecked, defaultTextValue, defaultTokenValue, loading, errorOpen, answer.length, processed, selectedSchema._id, selectedSchema]);

  return (
    <>
      <Grid item xs={12}>
        <Typography
          textAlign={'left'}
          minHeight={'4rem'}
          display={'flex'}
          alignItems={'center'}
          fontWeight={'bold'}
        >
          2. Könyv tördelése és elküldése a ChatGPT felé
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className='addfile'>
          <Typography>Könyv hozzáadása (kizárólag .txt fájl!)</Typography>
          {fileName && (
            <Typography>Jelenleg kiválasztott: {fileName}</Typography>
          )}
          <Button
            variant='outlined'
            color='secondary'
            component='label'
            sx={{ mb: 2 }}
          >
            Könyv feltöltése
            <input
              hidden
              type='file'
              onChange={handleFileChange}
              id='fragmentUpload'
              accept='.txt'
            ></input>
          </Button>
        </div>
        {
          version !== '4' && (
        <div>
        {
            requestSchema && requestSchema.length > 0 && (
              <>
                <FormControl fullWidth style={{ textAlign: 'left' }}>
                  <InputLabel id="model-selector-label">Sablon</InputLabel>
                  <Select
                    labelId="model-selector-label"
                    id="model-selector"
                    label="Sablon"
                    fullWidth={true}
                    onChange={handleRequestSchemaChange}
                    value={selectedSchema._id}
                  >

                    {requestSchema.map((schema, index) => (
                      <MenuItem key={index} value={schema._id}>{schema.name}</MenuItem>
                    ))}
                      <MenuItem value={'0'}>Teszt</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={() => {setOpenModal(true)}}>Sablon részletei</Button>
                <Modal
                  open={openModal}
                  onClose={handleModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  disableScrollLock={true}
                  className='modal add-schema-modal'
                >
                  <Box sx={style}>
                    <SchemaDetails data={selectedSchema} />
                    <Button onClick={handleModalClose}>
                      Bezárás
                    </Button>
                  </Box>
                </Modal>
                <Typography style={{fontStyle: 'italic'}}>
                      A sablonokat a <a href='/#/sablonok'>sablonok</a> oldalon tudod létrehozni és szerkeszteni.
                </Typography>
              </>
            )

          }
        </div>

          )
        }

      </Grid>
      <Grid item xs={12} md={4} textAlign={{ xs: 'left', md: 'center' }}>
        <Grid container spacing={1}>

        {
          version === '4' && (
            <Grid item xs={12}>
        {
            requestSchema && requestSchema.length > 0 && (
              <>
                <FormControl fullWidth style={{ textAlign: 'left' }}>
                  <InputLabel id="model-selector-label">Sablon</InputLabel>
                  <Select
                    labelId="model-selector-label"
                    id="model-selector"
                    label="Sablon"
                    fullWidth={true}
                    onChange={handleRequestSchemaChange}
                    value={selectedSchema._id}
                  >

                    {requestSchema.map((schema, index) => (
                      <MenuItem key={index} value={schema._id}>{schema.name}</MenuItem>
                    ))}
                      <MenuItem value={'0'}>Teszt</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={() => {setOpenModal(true)}}>Sablon részletei</Button>
                <Modal
                  open={openModal}
                  onClose={handleModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  disableScrollLock={true}
                  className='modal add-schema-modal'
                >
                  <Box sx={style}>
                    <SchemaDetails data={selectedSchema} />
                    <Button onClick={handleModalClose}>
                      Bezárás
                    </Button>
                  </Box>
                </Modal>
                <Typography style={{fontStyle: 'italic'}}>
                      A sablonokat a <a href='/#/sablonok'>sablonok</a> oldalon tudod létrehozni és szerkeszteni.
                </Typography>
              </>
            )

          }
        </Grid>

          )
        }

          {
              selectedSchema._id === '0' && (
                <>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isTokenChecked}
                          onChange={handleTokenCheckboxChange}
                          color='primary'
                        />
                      }
                      label='Alapértelmezett token érték'
                    />
                    <TextField
                      multiline={true}
                      helperText={tokenError ? 'Maxtoken megadása kötelező' : ''}
                      label={'Maxtoken'}
                      fullWidth={true}
                      type='number'
                      id='maxtoken'
                      onChange={handleTokenChange}
                      value={tokenValue}
                      disabled={isTokenChecked}
                      error={tokenError}
                      inputProps={{ min: 0, max: model === 'gpt-4' ? 8192 : 4096  }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom>Temperature</Typography>
                    <Slider
                      aria-label='Temperature'
                      valueLabelDisplay='auto'
                      step={0.1}
                      marks
                      min={0}
                      max={2}
                      label={'szia'}
                      value={temperature}
                      onChange={handleTemperatureChange}
                    />
                  </Grid>
                  <Grid item xs={12}>

                <TextField
                      multiline={false}
                      label={'Rövidítési arány (kikapcsoláshoz: legyen 0)'}
                      fullWidth={true}
                      id='ratio'
                      onChange={(event) => {setValidRatio(event.target.value)} }
                      value={validRatio}
                />

                </Grid>
                  <Grid item xs={12}>
                  <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    color='primary'
                  />
                }
                label='Alapértelmezett prompt használata'
              />
              <TextField
                multiline={true}
                helperText={error ? 'Prompt megadása kötelező' : ''}
                label={'Prompt 0-1200 szó között'}
                fullWidth={true}
                type='text'
                id='promp0t'
                onChange={(event) => {handleTextChange(event, 0)}}
                value={prompt0Value}
                disabled={isChecked}
                error={error}
                />
                  </Grid>
                  <Grid item xs={12}>

                <TextField
                multiline={true}
                helperText={error ? 'Prompt megadása kötelező' : ''}
                label={'Prompt 1200-2000 szó között'}
                fullWidth={true}
                type='text'
                id='prompt1200'
                onChange={(event) => {handleTextChange(event, 1)}}
                value={prompt1200Value}
                disabled={isChecked}
                error={error}
                />

                </Grid>
                  <Grid item xs={12}>
                <TextField
                multiline={true}
                helperText={error ? 'Prompt megadása kötelező' : ''}
                label={'Prompt 2000-2800 szó között'}
                fullWidth={true}
                type='text'
                id='prompt2000'
                onChange={(event) => {handleTextChange(event, 2)}}
                value={prompt2000Value}
                disabled={isChecked}
                error={error}
                />

                </Grid>
                  <Grid item xs={12}>
                <TextField
                multiline={true}
                helperText={error ? 'Prompt megadása kötelező' : ''}
                label={'Prompt 2800 szó felett'}
                fullWidth={true}
                type='text'
                id='prompt2800'
                onChange={(event) => {handleTextChange(event, 3)}}
                value={prompt2800Value}
                disabled={isChecked}
                error={error}
                />
                  </Grid>
                </>
              )
            }
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={1}>
        {
          models && models.length > 1 && model && (
            <Grid item xs={12}>
              <FormControl fullWidth style={{ textAlign: 'left' }}>
                <InputLabel id="model-select-label">Modell</InputLabel>
                <Select
                  labelId="model-select-label"
                  id="model-select"
                  label="Modell"
                  value={model._id}
                  onChange={handleModelChange}
                >
                  {models.map((model, index) => (
                    <MenuItem key={index} value={model._id}>{model.name}{model.default && " (alapértelmezett)"}</MenuItem>
                  ))
                  }
                </Select>
                <i style={{textAlign:"center"}}>(ne módosítsd)</i>
              </FormControl>
            </Grid>
          )
        }
          
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={savePrompt}
                  onChange={handleSavePromptChange}
                />
              }
              label='Eredeti megőrzése'
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant='contained'
              size='large'
              type='submit'
              disabled={!file}
              loading={loading}
              onClick={handleSubmit}
            >
              Elküldés
            </LoadingButton>
          </Grid>
          {
            loading && (
              <Grid item xs={12}>
                <AbortProcessing loading={loading}/>
              </Grid>
            )
          }

        </Grid>
      </Grid>
      {fileError && (
        <Grid item xs={12}>
          <Alert severity='error' sx={{ marginTop: 4 }}>
            Hiba történt a feldolgozás során!
          </Alert>
        </Grid>
      )}
      { answer && answer.length > 0 && (
        <Grid item xs={12} textAlign={'left'}>

        {
          loading && (
            <><CircularProgress />
              <Typography>
              Feldolgozás alatt: {localStorage.getItem('currentProcess')} / {localStorage.getItem('allChunks')} rész
            </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <LinearProgress variant="determinate" value={ (localStorage.getItem('currentProcess')/localStorage.getItem('allChunks') *100 )} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.secondary">{`${Math.round(
                    (localStorage.getItem('currentProcess')/localStorage.getItem('allChunks') )*100
                  )}%`}</Typography>
                </Box>
              </Box>
            </>
          )
        }
          <AnswerPanels answer={answer} savePrompt={savePrompt} />
        </Grid>
      )}
      <SnackbarProvider autoHideDuration={10000} />
    </>
  );
};

export default Fragment;
