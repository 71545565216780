import React from 'react';
import { Container, Grid } from '@mui/material';
import ListModels from '../Components/GptModels/ListModels';

const GptModelsPage = () => {

  if (localStorage.getItem('user_id') && localStorage.getItem('token')) {
    return (
      <>
        <Container sx={{ marginBottom: 4 }}>
          <Grid container spacing={2}>
            <ListModels />
          </Grid>
        </Container>
      </>
    );
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href = '/#/bejelentkezes';
    window.location.reload();
  }
};

export default GptModelsPage;
