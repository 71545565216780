import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import Looks3TwoTone from '@mui/icons-material/Looks3TwoTone';
import Convert from '../Components/Books/Convert';
import Fragment from '../Components/Books/Fragment.v10';
import FAQ from '../Components/FAQ/FAQ';
import LoadPrevious from '../Components/Books/LoadPrevious';

const MainPage = () => {

  if (localStorage.getItem('user_id') && localStorage.getItem('token')) {
    return (
      <>
        <Container sx={{ marginBottom: 4 }}>
          <Grid container spacing={2}>
            <Typography
              textAlign={'left'}
              minHeight={'4rem'}
              display={'flex'}
              alignItems={'center'}
              fontWeight={'bold'}
              marginTop={'2rem'}
            >
              <Looks3TwoTone color='secondary'/>Jelenleg a GPT-3.5 verziót használod.
            </Typography>
            <Convert />
            <Fragment />
            <FAQ />

            {
              localStorage.getItem('answers') && localStorage.getItem('compare') && (
            <LoadPrevious />
              )
            }
          </Grid>
        </Container>
      </>
    );
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location.href = '/#/bejelentkezes';
    window.location.reload(false);
  }
};

export default MainPage;
