import { Accordion, AccordionDetails, AccordionSummary, TextField, Typography } from '@mui/material';
import React, {  useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './ListSchemas.scss';

const SchemaDetails = ({ data }) => {
  const [name, setName] = useState( data ? data.name : '');
  const [prompt0, setPrompt0] = useState(data ? data.prompt0.prompt : '');
  const [prompt1200, setPrompt1200] = useState(data ? data.prompt1200.prompt : '');
  const [prompt2000, setPrompt2000] = useState(data ? data.prompt2000.prompt : '');
  const [prompt2800, setPrompt2800] = useState(data ? data.prompt2800.prompt : '');

  const [tokenValue0, setTokenValue0] = useState(data ? data.prompt0.maxtoken : 0);
  const [tokenValue1200, setTokenValue1200] = useState(data ? data.prompt1200.maxtoken : 0);
  const [tokenValue2000, setTokenValue2000] = useState(data ? data.prompt2000.maxtoken : 0);
  const [tokenValue2800, setTokenValue2800] = useState(data ? data.prompt2800.maxtoken : 0);

  const [temperature0, setTemperature0] = useState(data ? data.prompt0.temperature : 0.7);
  const [temperature1200, setTemperature1200] = useState(data ? data.prompt1200.temperature : 0.7);
  const [temperature2000, setTemperature2000] = useState(data ? data.prompt2000.temperature : 0.7);
  const [temperature2800, setTemperature2800] = useState(data ? data.prompt2800.temperature : 0.7);

  const [validRatio, setValidRatio] = useState(data ? data.validRatio : 0);

  const variants = [
    {value: '0',    text: '0-1200 közötti'}, 
    {value: '1200', text: '1200-2000 közötti'},
    {value: '2000', text: '2000-2800 közötti'},
    {value: '2800', text: '2800 feletti'}
  ];

  const getValues = (propName) => {
    switch (propName) {
      case 'name':
        return name;
      case 'validRatio':
        return validRatio;
      case 'prompt0':
        return prompt0;
      case 'prompt1200':
        return prompt1200;
      case 'prompt2000':
        return prompt2000;
      case 'prompt2800':
        return prompt2800;
      case 'tokenValue0':
        return tokenValue0;
      case 'tokenValue1200':
        return tokenValue1200;
      case 'tokenValue2000':
        return tokenValue2000;
      case 'tokenValue2800':
        return tokenValue2800;
      case 'temperature0':
        return temperature0;
      case 'temperature1200':
        return temperature1200;
      case 'temperature2000':
        return temperature2000;
      case 'temperature2800':
        return temperature2800;
      default:
        return '';
    }
  };

  return (
    <div>
        <Typography
          textAlign={'center'}
          fontWeight={'bold'}
          marginBottom={'2rem'}
        >
        {
          data ? `${data.name} sablon részletei` : 'Sablon részleteik'
        }
        </Typography>
        <TextField
          multiline={true}
          label={'Sablon neve'}
          fullWidth={true}
          value={getValues(`name`)}
          style={{marginBottom: '1rem'}}
        />
        
      {
        variants.map((variant, index) => {
          return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{variant.text} szószám beállításai:</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <TextField
              multiline={true}
              label={'Prompt'}
              fullWidth={true}
              value={getValues(`prompt${variant.value}`)}
              style={{marginBottom: '1rem'}}
            />
            <TextField
              multiline={true}
              label={'Maxtoken'}
              fullWidth={true}
              value={getValues(`tokenValue${variant.value}`)}
              style={{marginBottom: '1rem'}}
            />
            <Typography>
              Temperature: {getValues(`temperature${variant.value}`)}
            </Typography>
            </AccordionDetails>
          </Accordion>     
        )})
      }
        <TextField
          multiline={true}
          label={'Összehasonlítás arány'}
          fullWidth={true}          
          value={getValues(`validRatio`)}
          style={{marginBottom: '1rem', marginTop: '1rem'}}
        />
        A sablonokat a <a href='/#/sablonok'>sablonok</a> oldalon tudod szerkeszteni.
    </div>
  );
};

export default SchemaDetails;
