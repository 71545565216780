import './App.scss';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { routes } from './routes/routes';
import ButtonAppBar from './Components/AppBar/AppBar';

function App() {
  return (
    <div className='App'>
      <ButtonAppBar />
      <Router>
        <Routes>
          {routes.map((route) => (
            <Route
              exact
              key={route.path}
              path={route.path}
              element={route.page}
            />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
