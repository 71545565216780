import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Grid, Tab, TextField, Typography } from '@mui/material';
import FileSaver from 'file-saver';
import React from 'react';

const LoadPrevious = () => {
  const [value, setValue] = React.useState('0');
  const [show, setShow] = React.useState(false);

  const handleLoad = () => {
    setShow(!show);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDownload = (file, downloadFileName) => {
    const blob = new Blob([file], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, `${downloadFileName}.txt`);
  };

  return (
    <Grid item xs={12} sx={{ textAlign: 'left' }}>
      <Typography
        textAlign={'left'}
        alignItems={'center'}
        fontWeight={'bold'}
      >
        Előző összegzés visszatöltése:
      </Typography>
      {
        !show ? (
          <Button onClick={() => handleLoad()}
            variant="contained" color="success">
            Betöltés
          </Button>
        )
          : (
          <Button onClick={() => handleLoad()}
            variant="contained" color="secondary">
            Elrejtés
          </Button>)
      }
      {
        show && (
          <div>
            <TabContext value={value}>
              <TabList onChange={handleChange}>
                <Tab label='ChatGPT válasz' value='0' />
                <Tab label='Összehasonlítás' value='1' />
              </TabList>
              <TabPanel value='0'>
                <Button
                  variant='contained'
                  size='large'
                  onClick={() => handleDownload(localStorage.getItem('answers'), localStorage.getItem('fragment-uploaded') + '-chatgpt' || 'feldolgozott-konyv')}
                  sx={{ mb: 2, mt: 2 }}
                >
                  Letöltés
                </Button>
                <TextField
                  multiline={true}
                  fullWidth={true}
                  value={localStorage.getItem('answers')}
                  rows={16}>
                </TextField>
              </TabPanel>
              <TabPanel value='1'>
                <Button
                  variant='contained'
                  size='large'
                  onClick={() =>
                    handleDownload(localStorage.getItem('compare'), localStorage.getItem('fragment-uploaded') + '-osszehasonlitas' || 'feldolgozott-konyv-osszehasonlitas')}
                  sx={{ mb: 2, mt: 2 }}
                >
                  Letöltés
                </Button>
                <TextField
                  multiline={true}
                  fullWidth={true}
                  value={localStorage.getItem('compare')}
                  rows={16}>
                </TextField>
              </TabPanel>
            </TabContext>
          </div>
        )
      }
    </Grid>
  );
}

export default LoadPrevious;
