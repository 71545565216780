import GptModelsPage from '../Pages/GptModelsPage';
import LoginPage from '../Pages/LoginPage';
import MainPage from '../Pages/MainPage'
import MainPageGPT4 from '../Pages/MainPage-gpt-4';
import RegisterPage from '../Pages/RegisterPage';
import RequestSchemasPage from '../Pages/RequestSchemasPage';

export const routes = [
  {
      path: '/',
      page: <MainPageGPT4 />
  },
  {
      path: '/bejelentkezes',
      page: <LoginPage />
  },
  {
      path: '/regisztracio',
      page: <RegisterPage />
  },
  {
      path: '/gpt-3',
      page: <MainPage />
  },
  {
      path: '/sablonok',
      page: <RequestSchemasPage />
  },
  {
      path: '/modellek',
      page: <GptModelsPage />
  },
  {
      path: '*',
      page: <MainPageGPT4 />
  }
];
