import { Button, Modal } from '@mui/material';
import { Box, Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import './ListSchemas.scss';
import EditSchema from './EditSchema';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ListSchemas = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editData, setEditData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await fetch(process.env.REACT_APP_API_URL + '/schema', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }).then((response) => {
        if (response.status === 201) {
          return response.json();
        } else if (response.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          window.location.href = '/#/bejelentkezes';
          window.location.reload(false);
        }
      }).then((data) => {
        setData(data);
      }
      );
    }
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    await fetch(process.env.REACT_APP_API_URL + '/schema/' + id, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
    }).then((response) => {
      if (response.status === 201) {
        return response.json();
      } else if (response.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href = '/#/bejelentkezes';
        window.location.reload(false);
      }
    }).then((data) => {
      window.location.reload();
    }

    );
  }


  return (
    <Container>
      <h1>Sablonok</h1>
      <Button onClick={handleOpen} variant='contained' color='success'>
        <AddCircleIcon /> &nbsp;Új sablon hozzáadása
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={true}
        className='modal add-schema-modal'
      >
        <Box sx={style}>
          <EditSchema />
          <Button onClick={handleClose}>
            Mégse
          </Button>
        </Box>
      </Modal>

      <div style={{ textAlign: 'left' }}>

      <table className='schema-list'>
        <thead className='schema-list-header'>
          <tr className='schema-list-header-row'>
            <th className='schema-list-header-row-item'>Név</th>
            <th className='schema-list-header-row-item'>Módosítás</th>
            <th className='schema-list-header-row-item'>Törlés</th>
          </tr>
        </thead>
        <tbody className='schema-list-body'>
        {
          data &&
          data.map((item, index) => {
            return (
              <tr key={index} className='schema-list-body-row'>
                <td className='schema-list-body-row-item'>{item.name}</td>
                <td className='schema-list-body-row-item'><EditIcon className='schema-list-body-row-item-modify' onClick={() => {setEditData(item); handleEditModalOpen()}} /></td>
                <td className='schema-list-body-row-item'><DeleteForeverIcon className='schema-list-body-row-item-delete' onClick={() => handleDelete(item._id)} /></td>
              </tr>
            )
          }
          )
        }
      </tbody>
      </table>
      </div>
      <Modal
        open={editModalOpen}
        onClose={handleEditModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={true}
        className='modal add-schema-modal'
      >
        <Box sx={style}>
          <EditSchema data={editData} />
          <Button onClick={handleEditModalClose}>
            Mégse
          </Button>
        </Box>
      </Modal>


    </Container>
  );
};

export default ListSchemas;
