import React, { useState } from 'react';
import './styles/ObjectToTable.scss'

function ObjectToTable({ data }) {
  const [expandedRows, setExpandedRows] = useState([]);
  const [validRatio, setValidRatio] = useState(localStorage.getItem('wordRatio') || 0);  

  const toggleRow = (index) => {
    const expandedRowSet = new Set(expandedRows);
    if (expandedRowSet.has(index)) {
      expandedRowSet.delete(index);
    } else {
      expandedRowSet.add(index);
    }
    setExpandedRows(Array.from(expandedRowSet));
  };

  const totalWords = (data, field) => {
    let total = 0;
    data.forEach(row => {
      total += row[field];
    });
    return total;
  }

  return (
    <div className='comparison-table'>
      <div className='comparison-table-header'>
        <div className='comparison-table-header-row'>
          <div className='comparison-table-header-row-item'>Eredeti szöveg</div>
          <div className='comparison-table-header-row-item'>ChatGPT válasz</div>
          <div className='comparison-table-header-row-item'>Eredeti szószám</div>
          <div className='comparison-table-header-row-item'>Válasz szószám</div>
          <div className='comparison-table-header-row-item'>Válasz/Eredeti arány</div>
        </div>
      </div>
      <div className='comparison-table-body'>
        {data.map((row, index) => {
          let validRatioNumber = localStorage.getItem('wordRatio') ? Number(localStorage.getItem('wordRatio')) : 0;
          const invalidRatio = validRatioNumber !== 0 && (row.generatedWordCount / row.originalWordCount >= validRatioNumber);

          return (
            <div
              key={index}
              className={
                'comparison-table-body-row' + 
                ( invalidRatio ? ' invalid-ratio' : '') +
                ( row.status === 'error' ? ' wrong' : '') +
                (expandedRows.includes(index) ? ' expanded' : '')
              }
            >
            <div className='comparison-table-body-row-item' onClick={() => toggleRow(index)}>
              <div className='comparison-table-body-row-item-content'>{row.original}</div>
            </div>
            <div className='comparison-table-body-row-item' onClick={() => toggleRow(index)}>
              <div className='comparison-table-body-row-item-content'>{row.message}</div>
            </div>
            <div className='comparison-table-body-row-item'>{row.originalWordCount}</div>
            <div className='comparison-table-body-row-item'>{row.generatedWordCount}</div>
            <div className='comparison-table-body-row-item'>{row.generatedWordCount / row.originalWordCount ? String(Math.round(row.generatedWordCount / row.originalWordCount * 100) / 100) : '-'}</div>
          </div>) 
          
        })}
      </div>
      <div className='comparison-table-footer'>
        <div className='comparison-table-footer-row'>
          <div className='comparison-table-footer-row-item'>Összesen</div>
          <div className='comparison-table-footer-row-item'>{data.length} adatrész feldolgozva</div>
          <div className='comparison-table-footer-row-item'>{totalWords(data, 'originalWordCount')}</div>
          <div className='comparison-table-footer-row-item'>{totalWords(data, 'generatedWordCount')}</div>
          <div className='comparison-table-footer-row-item'>{String(Math.round(totalWords(data, 'generatedWordCount') / totalWords(data, 'originalWordCount') * 100) / 100)}</div>
        </div>
      </div>
    </div>
  );
}

export default ObjectToTable;
