import React from 'react';
import { Grid } from '@mui/material';

const FAQ = ({ version = '3.5' }) => {
  return (
    <Grid item xs={12} sx={{ textAlign: 'left' }}>
    <strong>Az alkalmazás használata:</strong>
    <ol>
      <li>
      <strong>Könyv átalakítása:</strong>
      <br />
      <ol>
        <li>
          Töltsünk fel egy könyvet az alkalmazás '1. Ekönyv átalakítsa szöveggé' szakaszában a Könyv feltöltése gombbal. Ez jelenleg kizárólag .epub, .mobi, .pdf formátumú lehet.
        </li>
        <li>
          Az átalakítás gombra kattintva rövid idő múlva elindul a letöltés. A file.txt fájl a könyvet egy szöveges formában tartalmazza.
        </li>
      </ol>
      </li>
      <li>
      <strong>Könyv tördelése és elküldése a ChatGPT felé:</strong>
      <ol>
        <li>
        A letöltött fájlban helyezzünk el -x- karaktersorozatokat ott, ahol szeretnénk tördelni a fájlt. Ügyeljünk rá, hogy a szövegrész tartalma és a megadott prompt hossza max. { version === '4' ? '6000' : '3000' } token (kb. { version === '4' ? '24.000' : '12.000' } karakter) legyen - <strong>de ennél jóval kevesebb, kb. 1000 szó az ideális.</strong>
        </li>
        <li>
          -xxx- karaktersorozatokat helyezhetünk el egy kihagyni kívánt rész előtt. A program ezt a részt nem küldi el a ChatGPT felé. <br />
          <div>
            Példa:<br /> 
            ...<br />
            -xxx-<br />
            Ez egy cím<br />
            -x-<br />
            ...<br />
          </div>
          Ekkor a program a 'Ez egy cím' szövegrészt nem küldi el a ChatGPT felé.
        </li>
        <li>
          Töltsük fel a módosított fájlt a '2. Könyv tördelése és elküldése a ChatGPT felé' szakaszban a Könyv feltöltése gombbal.
        </li>
        <li>
          Válasszunk sablont a legördülő menüből. A sablonok elérhetőek és módosíthatóak a <a href='/#/sablonok'>sablonok</a> menüpont alatt. FONTOS: A sablonok az összes felhasználó számára ugyanazok, így érdemes megfontolni a módosításokat és törléseket.
        </li>
        <li>
          Eredeti megőrzése gomb használata: bekapcsolásakor az elküldött szövegrészlet is látszik, így könnyen összehasonlítható az eredeti és a válasz is. (A prompt ekkor nem látszódik már!)
        </li>
        <li>
        <div>
          Az elküldés gomb megnyomása után a szövegrészletek feldolgozásra kerülnek. A válaszadásig több perc is eltelhet a szövegrészletek mennyiségétől függően. A válasz megérkezésekor az oldal alján megjelenő füleken válthatunk a nézetek között. A ChatGPT válasz tabon kizárólag a feldolgozott adatokat láthatjuk, míg az Összehasonlítás tabon az elküldött üzenet (prompt nélkül) és a ChatGPT-től adott válasz, majd egy választóvonal. A szövegmezők feletti letöltés gombbal van lehetőségünk az itt megjelenített szövegeket letölteni.</div>
        <div>
          <strong>Megszakítás: </strong>Amennyiben szeretnénk megszakítani a feldolgozás folyamatot a befejezés előtt vagy bezárni a böngésző ablakot, használjuk a 'Megszakítás' gombot. Ekkor a feldolgozás leáll. Az aktuálisan beküldött rész még feldolgozásra kerül, de a többi már nem, ezért beletelhet néhány percbe, mire a folyamat megszakad, addig <span style={{color: 'red', fontWeight: '700'}}>NE</span> zárjuk be a böngésző ablakot! Miután megszakadt feldolgozási folyamat, a gomb eltűnik.
        </div>
        <div>
          <strong>Feldolgozáskor megjelenő üzenetek:</strong> <br />
            A jobb felső sarokban a feldolgozás státuszát jelző üzenetek jelennek meg. Ezek a következők lehetnek:
            <ul>
              <li>
                <span style={{color: 'green'}}>Zöld:</span> a feldolgozás sikeresen befejeződött.
              </li>
              <li>
                <span style={{color: 'red'}}>Piros:</span> hiba történt a feldolgozás során. A hibaüzenet a válaszmezőben jelenik meg. FONTOS: A feldolgozás nem áll le egy-egy hibás szakasznál: lehet, hogy a ChatGPT túlterhelt és ezért nem kerül továbbítása 1-1 üzenet, ezeket külön be kell küldeni a feldolgozási folyamat után.
              </li>
              <li>
              <span style={{color: 'orange'}}>Narancssárga:</span> Az szószámok arányára vonatkozó jelzés. Amennyiben megjelenik, úgy a generált szöveg túl hosszú az elvárthoz képest. Sablononként eltérő lehet. Nem áll le a feldolgozás ilyen üzenet esetén, csupán egy jelzés a felhasználó felé.</li>
            </ul>
        </div>
        </li>        
      </ol>
      </li>
      <li>
        <strong>Előző feldolgozás visszatöltése:</strong>
        <div>
          Amennyiben már dolgoztunk fel egy fájlt, úgy az oldal alján megjelenik az 'Előző összegzés visszatöltése' szakasz. Itt a Betöltés gombbal tudjuk megnyitni a korábban feldolgozott üzeneteket és letölteni ezek tartalmát. A táblázat nézet ilyenkor már nem elérhető. Az Elrejtés gombbal elrejthetjük a szakaszt, ha nem szeretnénk használni. 
        </div>
      </li>
      <li>
        <div>
          <strong>Kapcsolat, hibák jelentése:</strong>
          <br />
          Amennyiben a program működésében hibát tapasztalsz, írj Discordon: <a href='https://discordapp.com/users/1072117056574607360' target='_blank' rel="noreferrer">papimre#4787</a>
        </div>
      </li>

    </ol>      
    </Grid>
  )
}

export default FAQ;
