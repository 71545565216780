import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import axios from "axios";

const logout = async (e) => {
  e.preventDefault();
    axios
      .post(process.env.REACT_APP_API_URL + '/api/logout', null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      })
      .then((result) => {
          localStorage.removeItem("token");
          localStorage.removeItem("user_id");
          window.location.href = '/#/bejelentkezes';
          window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        window.location.href = '/#/bejelentkezes';
        window.location.reload(false);
      });
};

const handleClick = (location) => {
  window.location.href = location;
}

const toLoginPage = (e) => {
  e.preventDefault();
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
  window.location.href = '/#/bejelentkezes';
  window.location.reload(false);
}

export default function ButtonAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "left" }}>
            Könyvösszefoglaló
          </Typography>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button sx={{ color: "#fff" }} onClick={(e) => handleClick('/#/')}>Átalakítás</Button>
            <Button sx={{ color: "#fff" }} onClick={(e) => handleClick('/#/sablonok')}>Sablonok</Button>
            <Button sx={{ color: "#fff" }} onClick={(e) => handleClick('/#/modellek')}>Modellek</Button>
          </Box>
          {localStorage.getItem("token") ? (
            <Button color="inherit" onClick={logout}>Kijelentkezés</Button>
          ) : (
            <Button color="inherit" onClick={toLoginPage}>Bejelentkezés</Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
