import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { Container } from '@mui/system';

function LoginPage() {
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [loginerror, setLoginError] = useState(false);

  function registerUser(event) {
    event.preventDefault();
    axios
      .post(process.env.REACT_APP_API_URL + '/api/login', {
        email,
        password,
      })
      .then((result) => {
        if (result.status === 200) {
          localStorage.setItem('token', result.data.token);
          localStorage.setItem('user_id', result.data.user_id);
          window.location.href = '/#/';
          window.location.reload(false);
        } else if (result.status === 403) {
          setLoginError(true);
        }
      })
      .catch((error) => {
        setLoginError(true);
      });
  }

  if (localStorage.getItem('user_id') && localStorage.getItem('token')) {
    setTimeout(() => {
      window.location.href = '/#/';
      window.location.reload(false);
    }, 500);
  } else {
    return (
      <div>
        <Container>
          <h1>Bejelentkezés</h1>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                type='email'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setLoginError(false);
                }}
                className='form-control'
                id='email'
                aria-describedby='Email cím'
                placeholder='E-mail cím'
                variant='outlined'
                error={loginerror}
                label='E-mail cím'
                required
                sx={{ mb: 2 }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant='outlined'
                required
                error={loginerror}
                className='form-control'
                sx={{ mb: 4 }}
              >
                <InputLabel htmlFor='outlined-adornment-password'>
                  Jelszó
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password'
                  value={password}
                  type='password'
                  inputProps={{ minLength: 6, maxLength: 32 }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setLoginError(false);
                  }}
                  placeholder='Jelszó'
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='contained'
                size='large'
                type='submit'
                className='login-button'
                aria-label='Bejelentkezés'
                onClick={registerUser}
              >
                Bejelentkezés
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default LoginPage;
